section.Links {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

section.Links > div,
section.Links > form,
section.Links > article {
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;
    border: 1px dashed #000;
}

@media (max-width: 768px) {
    
    section.Links > div,
    section.Links > form,
    section.Links > article {
        height: 100vh;
        border: none;
    }
}

section.Links > div > svg,
section.Links > form > svg {
    height: 80px;
    margin-bottom: 20px;
}

section.Links > form.password > input {
    width: 100%;
    max-width: 280px;
    height: 40px;
    background-color: #eee;
    padding: 0px 10px;
    box-sizing: border-box;
    border: none;
    border-radius: 3px;
    margin-bottom: 20px;
}

section.Links > form.password > button {
    background: none;
    border: none;
}

section.Links > article.player {
    justify-content: flex-start;
    padding: 0px;
}

section.Links > article.player > header,
section.Links > article.player > footer {
    width: 100%;
    padding: 20px 40px;
    box-sizing: border-box;
}

section.Links > article.player > main {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #424242;
}